import React from "react";
import { FiLogOut } from "react-icons/fi";
import { IconContext } from "react-icons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Auth } from "aws-amplify";

function Navigation(props) {
  console.log(props.user);
  function handleLogout() {
    Auth.signOut().then(() => console.log("User signed out successfully"));
  }

  return (
    <>
      <Navbar expand="lg" className="navbar-container justify-content-between">
        <Container fluid>
          <Navbar.Brand href="https://www.tehrihills.com/">
            <img
              src="./tehri-hills.png"
              alt="my logo img"
              height="75"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav>
              {/* <Nav.Link
                style={{ cursor: "pointer" }}
                role="navigation"
                as={Link}
                to="/"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Home
                    </span>
                    <FaHome style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                style={{ cursor: "pointer" }}
                role="navigation"
                as={Link}
                to="/summary"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Summary
                    </span>
                    <FaDisplay style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                onClick={handleFiltersShow}
                style={{ cursor: "pointer" }}
                role="navigation"
              >
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Filters
                    </span>
                    <FaFilter style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link onClick={handleShow} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Color Palette
                    </span>
                    <CgColorPicker style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
              <Nav.Link
                //to="/"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                <IconContext.Provider value={{ color: "white", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Export
                    </span>
                    <FiDownload style={{ color: "grey" }} />
                  </div>
                </IconContext.Provider>
              </Nav.Link> */}
              <Nav.Link>
                <div className="d-flex">
                  <span
                    style={{
                      //marginRight: "10px",
                      verticalAlign: "middle",
                      color: "grey",
                      textTransform: "none",
                      //cursor: "pointer",
                    }}
                  >
                    Hello! {props.user.username}
                  </span>
                </div>
              </Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ cursor: "pointer" }}>
                <IconContext.Provider value={{ color: "grey", size: 20 }}>
                  <div className="d-flex">
                    <span
                      style={{
                        marginRight: "10px",
                        verticalAlign: "middle",
                        color: "grey",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Logout
                    </span>
                    <FiLogOut />
                  </div>
                </IconContext.Provider>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
