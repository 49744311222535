import React from "react";

import { ProjectTable } from "./ProjectTable";

export default function Home(props) {
  return (
    <div>
      <ProjectTable user={props.user} />
    </div>
  );
}
