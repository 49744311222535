import React, { useMemo } from "react";
import { useTable } from "react-table";
import { COLUMNS } from "./columns";
import "./table.css";
import data from "./data.js";

export const ProjectTable = (props) => {
  const columns = useMemo(() => COLUMNS, []);
  //const { dashboardData } = useAPI();

  function fetchFilteredData() {
    if (props && props.user) {
      return data.filter((item) => item.username === props.user.username);
    } else return [];
  }

  // useEffect(() => {
  //   const fetchProjectData = async () => {
  //     try {
  //       const response = await axios
  //         .get("http://localhost:5000/users/jared@example.com")
  //         .then((response) => response.data)
  //         .catch((err) => console.log(err));

  //       if (response && response.clients.length > 0) {
  //         var test = response.clients.map((client) => {
  //           return client.projects.map((project) => {
  //             return {
  //               clientId: client.id,
  //               userId: client.userId,
  //               clientName: client.name,
  //               projectName: project.name,
  //               projectId: project.id,
  //               url: project.url,
  //             };
  //           });
  //         });

  //         setData(test[0]);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchProjectData();
  // }, []);

  const tableInstance = useTable({
    columns,
    data: fetchFilteredData(),
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
