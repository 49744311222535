import { FaChartArea } from "react-icons/fa";

export const COLUMNS = [
  // { accessor: "clientId", Header: "Client Id" },
  // { accessor: "clientName", Header: "Client" },
  // {
  //   id: "isActive",
  //   accessor: (d) =>
  //     d.isActive ? (
  //       <input type="checkbox" disabled checked></input>
  //     ) : (
  //       <input type="checkbox" disabled></input>
  //     ),
  //   Header: "Is Active",
  // },
  // { accessor: "userId", Header: "User Id" },
  {
    accessor: "projectId",
    Header: () => (
      <div
        style={{
          textAlign: "center",
        }}
      >
        Project Id
      </div>
    ),
    Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
  },
  {
    accessor: "projectName",
    Header: () => (
      <div
        style={{
          textAlign: "center",
        }}
      >
        Project Name
      </div>
    ),
    Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
  },
  {
    accessor: "url",
    Header: "Report",
    Cell: (e) => (
      <div style={{ textAlign: "center" }}>
        <a href={`https://${e.value}.dataappinsights.com/`} target="_blank">
          <FaChartArea style={{ color: "grey" }} />
        </a>
      </div>
    ),
  },
  {
    accessor: "wurl",
    Header: "Import for Weighted-Report",
    Cell: (e) => (
      <div style={{ textAlign: "center" }}>
        <a href={`https://${e.value}.dataappinsights.com/`} target="_blank">
          Click here
        </a>
      </div>
    ),
  },
];

function openLink(path) {
  window.location.href = path;
}
