const data = [
  {
    username: "jared",
    userId: 1,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "jared",
    userId: 1,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 2,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "rajesh",
    userId: 2,
    clientId: 1,
    clientName: "TehriHills",
    isActive: true,
    projectId: 1,
    projectName: "Demo Research Project - 1",
    url: "demo",
    wurl: "demo",
  },
  {
    username: "rajesh",
    userId: 2,
    clientId: 2,
    clientName: "TehriHills",
    isActive: true,
    projectId: 2,
    projectName: "Demo Research Project - 2",
    url: "consumer-pulse",
    wurl: "tehrihills",
  },
  {
    username: "rajesh",
    userId: 2,
    clientId: 2,
    clientName: "CMB",
    isActive: true,
    projectId: 3,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "becky",
    userId: 3,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "becky",
    userId: 3,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 2,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "allie.starbird",
    userId: 4,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "allie.starbird",
    userId: 4,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 2,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "caitlin.dailey",
    userId: 5,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "caitlin.dailey",
    userId: 5,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 2,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "abhilash.mazumder",
    userId: 6,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "abhilash.mazumder",
    userId: 6,
    clientId: 2,
    clientName: "TehriHills",
    isActive: true,
    projectId: 2,
    projectName: "TH",
    url: "tehrihills",
    wurl: "demo",
  },
  {
    username: "abhilash.mazumder",
    userId: 6,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 3,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "rajan.panwar",
    userId: 7,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "anne.smith",
    userId: 8,
    clientId: 1,
    clientName: "TehriHills",
    isActive: true,
    projectId: 1,
    projectName: "Demo Research Project - 1",
    url: "demo",
    wurl: "demo",
  },
  {
    username: "anne.smith",
    userId: 8,
    clientId: 2,
    clientName: "TehriHills",
    isActive: true,
    projectId: 2,
    projectName: "Demo Research Project - 2",
    url: "tehrihills",
    wurl: "tehrihills",
  },
  {
    username: "cmb_th",
    userId: 9,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "DSR channel foundational research",
    url: "dsr",
    wurl: "dsrw",
  },
  {
    username: "cmb_th",
    userId: 9,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 2,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "testuser",
    userId: 10,
    clientId: 1,
    clientName: "TehriHills",
    isActive: true,
    projectId: 1,
    projectName: "Demo Research Project - 1",
    url: "demo",
    wurl: "demo",
  },
  {
    username: "testuser",
    userId: 10,
    clientId: 2,
    clientName: "TehriHills",
    isActive: true,
    projectId: 2,
    projectName: "Demo Research Project - 2",
    url: "tehrihills",
    wurl: "tehrihills",
  },
  {
    username: "sungc",
    userId: 11,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "waqara",
    userId: 12,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "piaseckig",
    userId: 13,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
  {
    username: "deavilas",
    userId: 14,
    clientId: 1,
    clientName: "CMB",
    isActive: true,
    projectId: 1,
    projectName: "Customer Pulse Brand Trust",
    url: "consumer-pulse",
    wurl: "consumer-pulse-weighted",
  },
];

export default data;
