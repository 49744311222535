import React from "react";
import Navigation from "./Nav";

const Header = (props) => {
  console.log(props.user);
  return (
    <div>
      <Navigation user={props.user} />
    </div>
  );
};

export default Header;
