import React, { useEffect, useState, useContext, createContext } from "react";
import api from "./api";

const ApiContext = createContext();

export function APIContextProvider({ children }) {
  const initialData = {
    data: [],
  };

  const [dashboardData, setDashboardData] = useState(initialData);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await api.get(
  //       `http://localhost:5000/users/jared@example.com`,
  //       {
  //         // headers: {
  //         //   "auth-token": localStorage.getItem(
  //         //     "CognitoIdentityServiceProvider.2mcddm1k2vvpptc4m9phk3gja3.testuser.accessToken"
  //         //   ),
  //         // },
  //       }
  //     );
  //     // .then((response) => setDashboardData(response.data.data))
  //     // .catch((error) => console.log(error.message));

  //     // const datamap = await api.get(
  //     //   `https://d51jpha3w0.execute-api.ap-southeast-2.amazonaws.com/dev/datamap/${SURVEY_ID}`,
  //     //   {
  //     //     headers: {
  //     //       "auth-token": localStorage.getItem(
  //     //         "CognitoIdentityServiceProvider.2mcddm1k2vvpptc4m9phk3gja3.testuser.accessToken"
  //     //       ),
  //     //     },
  //     //   }
  //     // );

  //     // const summary = await api.get(
  //     //   `https://d51jpha3w0.execute-api.ap-southeast-2.amazonaws.com/dev/summary/${SURVEY_ID}`,
  //     //   {
  //     //     headers: {
  //     //       "auth-token": localStorage.getItem(
  //     //         "CognitoIdentityServiceProvider.2mcddm1k2vvpptc4m9phk3gja3.testuser.accessToken"
  //     //       ),
  //     //     },
  //     //   }
  //     // );
  //     // .then((response) => setDashboardData(response.data.data))
  //     // .catch((error) => console.log(error.message));

  //     setDashboardData({
  //       data: data.data.data,
  //       // datamap: datamap.data.data,
  //       // summary: summary.data.data,
  //     });
  //     //if (data) setDashboardData(data);
  //   };

  //   fetchData();
  // }, []);

  return (
    <ApiContext.Provider value={{ dashboardData }}>
      {children}
    </ApiContext.Provider>
  );
}

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useAPI() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
